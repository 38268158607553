import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { PageOurApproachQuery } from '@generated/GraphqlTypes';
import Base from '@webLayouts/Base/Base';
import OurApproach from '@webPages/About/OurApproach/OurApproach';

const OurApproachPage: React.FC<PageProps<PageOurApproachQuery>> = ({
  data,
}) => {
  const title = data?.ourApproach?.frontmatter?.title || '';
  const body = data?.ourApproach?.html || '';

  const seoTitle = data?.ourApproach?.frontmatter?.seoTitle || undefined;
  const seoDescription =
    data?.ourApproach?.frontmatter?.seoDescription ||
    data?.ourApproach?.excerpt ||
    undefined;

  const image =
    data?.ourApproach?.frontmatter?.heroImage?.publicURL || undefined;

  const top = {
    title: data?.ourApproach?.frontmatter?.namedSections?.top?.title || '',
    imageMobile: getImage(
      data?.ourApproach?.frontmatter?.heroImageMobile as ImageDataLike
    ),
    imageDesktop: getImage(
      data?.ourApproach?.frontmatter?.heroImage as ImageDataLike
    ),
    columns: (
      data?.ourApproach?.frontmatter?.namedSections?.top?.columns || []
    ).map((column: any) => ({
      title: column.title || '',
      body: column.body || '',
      linkText: column.linkText || undefined,
      linkUrl: column.linkUrl || undefined,
    })),
  };

  const middle = {
    title: data?.ourApproach?.frontmatter?.namedSections?.middle?.title || '',
    images: (
      data?.ourApproach?.frontmatter?.namedSections?.middle?.images || []
    ).map((a: unknown) => getImage(a as ImageDataLike) as IGatsbyImageData),
    parts: (
      data?.ourApproach?.frontmatter?.namedSections?.middle?.parts || []
    ).map((part: any) => ({
      title: part.title || '',
      body: part.body || '',
    })),
  };

  const ourStory = {
    title: data?.ourApproach?.frontmatter?.namedSections?.ourStory?.title || '',
    body: data?.ourApproach?.frontmatter?.namedSections?.ourStory?.body || '',
    image: getImage(
      data?.ourApproach?.frontmatter?.namedSections?.ourStory
        ?.image as ImageDataLike
    ) as IGatsbyImageData,
  };

  return (
    <Base
      theme="light"
      title={seoTitle}
      description={seoDescription}
      image={image}
    >
      <OurApproach
        title={title}
        body={body}
        top={top}
        middle={middle}
        ourStory={ourStory}
      />
    </Base>
  );
};

export default OurApproachPage;

export const query = graphql`
  query PageOurApproach {
    ourApproach: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "our-approach" } }
    ) {
      html
      excerpt
      frontmatter {
        title
        seoTitle
        seoDescription
        heroImage {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 2000
              # placeholder: BLURRED
              # quality: 70
              # formats: [AUTO, WEBP, AVIF, JPG]
            )
          }
        }
        heroImageMobile {
          childImageSharp {
            gatsbyImageData(
              width: 500
              # placeholder: BLURRED
              # quality: 70
              # formats: [AUTO, WEBP, AVIF, JPG]
            )
          }
        }
        namedSections {
          top {
            title
            columns {
              title
              body
              linkText
              linkUrl
            }
          }
          middle {
            title
            images {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  # placeholder: BLURRED
                  # quality: 70
                  # formats: [AUTO, WEBP, AVIF, JPG]
                )
              }
            }
            parts {
              title
              body
            }
          }
          ourStory {
            title
            body
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 518
                  # placeholder: BLURRED
                  # quality: 100
                  # formats: [AUTO, WEBP, AVIF, JPG]
                )
              }
            }
          }
        }
      }
    }
  }
`;
