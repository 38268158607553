import React from 'react';
import * as styles from './readMore.module.scss';
import Icon from '@webAtoms/Icon/Icon';

export interface ReadMoreProps {
  bold?: boolean;
  children?: React.ReactNode;
  href?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  target?: '_blank';
}

const ReadMore: React.FC<ReadMoreProps> = ({
  bold,
  children,
  href,
  onClick,
  target,
}) => {
  const classNames = [bold ? styles.readMoreBold : ''].join(' ');

  return React.createElement(
    href ? 'a' : 'button',
    {
      className: styles.readMore,
      role: 'button',
      onClick: onClick,
      target,
      href,
      rel: href && target === '_blank' ? 'noopener noreferrer' : undefined,
    },
    <span className={styles.readMoreInner}>
      <span>{children}</span>
      <Icon name="arrowRight" />
    </span>
  );
};

export default ReadMore;
