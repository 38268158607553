import React from 'react';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import * as styles from './ourStory.module.scss';

export interface OurStoryProps {
  title: string;
  body: string;
  image?: IGatsbyImageData;
}

const OurStory: React.FC<OurStoryProps> = ({ title, body, image }) => {
  return (
    <Box>
      <Container>
        <Box className={styles.ourStorySection}>
          <Box className={styles.ourStorySectionImageWrapper}>
            {image && (
              <GatsbyImage image={image} alt="Ashley Beaumont" loading="lazy" />
            )}
          </Box>

          <Box>
            <Box className={styles.ourStorySectionHeading}>
              <h2>{title}</h2>
            </Box>
            <Box className={styles.ourStorySectionColumn}>
              {body.split('\n\n').map((p: string, i: number) => (
                <p key={i}>{p}</p>
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default OurStory;
