import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import * as Route from '@routes';
import * as styles from './goodForYou.module.scss';

export interface GoodForYouProps {
  title: string;
  images: IGatsbyImageData[];
  parts: GoodForYouPart[];
}

export interface GoodForYouPart {
  title: string;
  body: string;
}

const GoodForYou: React.FC<GoodForYouProps> = ({ title, images, parts }) => {
  return (
    <Box>
      <Container>
        <Box className={styles.goodForYouSection}>
          <Box className={styles.goodForYouSectionImageWrapper}>
            {images.map((image, index) => (
              <GatsbyImage
                key={index}
                image={image}
                alt={title}
                loading="lazy"
              />
            ))}
          </Box>

          <Box>
            <Box className={styles.goodForYouSectionHeading}>
              <h2>{title}</h2>
            </Box>
            <Box className={styles.goodForYouSectionColumns}>
              {parts.map(part => (
                <Box
                  key={part.title}
                  className={styles.goodForYouSectionColumn}
                >
                  <h3>{part.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: Route.linkProducts(part.body),
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default GoodForYou;
