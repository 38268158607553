import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import Box from '@webAtoms/Box/Box';
import ReadMore from '@webMolecules/ReadMore/ReadMore';
import Container from '@webAtoms/Container/Container';
import * as styles from './weSpecialise.module.scss';
import * as Route from '@routes';

export interface WeSpecialiseProps {
  imageMobile?: IGatsbyImageData;
  imageDesktop?: IGatsbyImageData;
  title: string;
  columns: WeSpecialiseColumn[];
}

export interface WeSpecialiseColumn {
  title: string;
  body: string;
  linkText?: string;
  linkUrl?: string;
}

const WeSpecialise: React.FC<WeSpecialiseProps> = ({
  imageDesktop,
  imageMobile,
  title,
  columns,
}) => {
  return (
    <Box>
      <Container>
        <Box className={styles.weSpecialiseInner}>
          {imageMobile && (
            <Box mobile={!!imageDesktop}>
              <GatsbyImage loading="lazy" image={imageMobile} alt={title} />
            </Box>
          )}
          {imageDesktop && (
            <Box desktop={!!imageMobile}>
              <GatsbyImage loading="lazy" image={imageDesktop} alt={title} />
            </Box>
          )}

          <Box className={styles.weSpecialiseSection}>
            <Box>
              <h2>{title}</h2>
            </Box>
            <Box className={styles.weSpecialiseSectionColumns}>
              {columns.map(column => (
                <Box
                  key={column.title}
                  className={styles.weSpecialiseSectionColumn}
                >
                  <h3>{column.title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: Route.linkProducts(column.body),
                    }}
                  />
                  {column.linkUrl && (
                    <ReadMore href={column.linkUrl}>
                      {column.linkText || 'Read more'}
                    </ReadMore>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default WeSpecialise;
